import { Link } from 'react-router-dom';
import SocialMedia from '../socialMedia/SocialMedia';
import LogoLink from '../logoLink/LogoLink';

import routes from '../../routes';

import './footer.scss';
import './mediaFooter.scss';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='container'>
        <h4 className='footer__appeal'>
          если ты cмотришь за пределами россии, <br className='footer__appeal__divider1' /> тебе сюда. welcome.{' '}
          <br className='footer__appeal__divider' />
          <a
            href='https://welcome.ufcfightpass.com/region/russia'
            target='_blank'
            className='footer__appeal_link-red'
            rel='noreferrer'
          >
            welcome.ufcfightpass.com
          </a>
        </h4>
        <div className='footer__wrapper footer__wrapper_ufc'>
          <div className='footer__left-column'>
            <LogoLink colorOfLogo='white' logoClass='footer__logo'></LogoLink>

            <div className='footer__sole-trader footer__sole-trader_768px'>
              <p className='footer__info paragraph paragraph_grey-secondary'>ИП Маслов Антон Павлович </p>
              <p className='footer__info paragraph paragraph_grey-secondary'> ИНН: 744724136382 </p>
              <p className='footer__info paragraph paragraph_grey-secondary'>ОГРНИП: 320745600057319</p>
            </div>
          </div>

          <div className='footer__contacts'>
            <div className='footer__chapter'>
              <h4 className='footer__header h2-mobile'>о UFC</h4>
              <ul className='footer__list'>
                <li className='footer__list-item paragraph paragraph_light-grey'>
                  <a className='footer__link' target='_blank' href='https://ufc.ru/about/sport' rel='noreferrer'>
                    Про ММА
                  </a>
                </li>
                <li className='footer__list-item paragraph paragraph_light-grey'>
                  <a className='footer__link' target='_blank' href='https://ufc.ru/about/community' rel='noreferrer'>
                    Социальная ответственность
                  </a>
                </li>
                <li className='footer__list-item paragraph paragraph_light-grey'>
                  <a className='footer__link' target='_blank' href='https://ufc.com/careers' rel='noreferrer'>
                    Вакансии
                  </a>
                </li>
                <li className='footer__list-item paragraph paragraph_light-grey'>
                  <a
                    className='footer__link'
                    target='_blank'
                    href='https://ufcstorerussia.ru/?utm_source=ufc'
                    rel='noreferrer'
                  >
                    Магазин
                  </a>
                </li>
              </ul>
            </div>
            <div className='footer__chapter'>
              <h4 className='footer__header h2-mobile'>Контакты</h4>
              <ul className='footer__list'>
                <li className='footer__list-item paragraph paragraph_light-grey'>
                  <a className='footer__link' href='https://ufc.ru/partners' target='_blank' rel='noreferrer'>
                    Партнерам
                  </a>
                </li>
                <li className='footer__list-item paragraph paragraph_light-grey'>
                  <a
                    className='footer__link'
                    target='_blank'
                    href='https://access.ufc.com/media_users/sign_in'
                    rel='noreferrer'
                  >
                    Пресс-центр
                  </a>
                </li>
              </ul>
            </div>
            <div className='footer__chapter'>
              <h4 className='footer__header h2-mobile'>Юр. раздел</h4>
              <ul className='footer__list'>
                <li className='footer__list-item paragraph paragraph_light-grey'>
                  <Link to={routes.agreement} className='footer__link'>
                    Пользовательское соглашение
                  </Link>
                </li>
                <li className='footer__list-item paragraph paragraph_light-grey'>
                  <Link to={routes.privacy} className='footer__link'>
                    Политика конфиденциальности
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='footer__wrapper footer__wrapper_social-media'>
          <p className='footer__info paragraph paragraph_grey-secondary'>
            Copyright © 2001-2024 UFC Все права защищены
          </p>
          <div className='footer__sole-trader footer__sole-trader_320px'>
            <p className='footer__info paragraph paragraph_grey-secondary footer__info_sole-trader'>
              ИП Маслов Антон Павлович{' '}
            </p>
            <p className='footer__info paragraph paragraph_grey-secondary footer__info_sole-trader'>
              {' '}
              ИНН: 744724136382
            </p>
            <p className='footer__info paragraph paragraph_grey-secondary footer__info_sole-trader'>
              ОГРНИП: 320745600057319
            </p>
          </div>
          <SocialMedia
            specificWrapperClass='social-media_footer'
            specificItemClass='social-media__link_grey-secondary'
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;

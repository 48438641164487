// https://ufcfightpass.smalyu.ru/api/v1/ - test server
// https://ufcfightpass.ru/api/v1/ - production server
const _apiURL = 'https://ufcfightpass.ru/api/v1/';
const _switcher = false;
// pk_7ac63433084f86b2bafcb5880e1a6 - test public id
// pk_28c66dbb88a749098f33d884e2723 - production public id
const _publicId = 'pk_28c66dbb88a749098f33d884e2723';

// the prices which uses in landing page
const landingPrices = [399, 249, 2999];

// the prices which uses for calculating prices with discount
const defaultPrices = [399, 2999];

export { _switcher, _publicId, landingPrices, defaultPrices };
export default _apiURL;


import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  paymentChoice: 'sberpay',
  isReccurent: false,
  sale_promocode: null,
};

export const paymentChoiceSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setPaymentChoice: (state, action) => {
      state.paymentChoice = action.payload;
    },
    setIsReccurent: (state, action) => {
      state.isReccurent = action.payload;
    },
    setSalePromocode: (state, action) => {
      state.sale_promocode = action.payload;
    },
    removeSalePromocode: (state, action) => {
      state.sale_promocode = null;
      localStorage.removeItem('discount_year');
    },
  },
});

export const { setPaymentChoice, removePaymentChoice, setIsReccurent, setSalePromocode, removeSalePromocode } =
  paymentChoiceSlice.actions;
